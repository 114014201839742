import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Api, Utility, EventBus } from 'src/helpers/new';
import Switcher from 'src/components/Switcher';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedIn, setSecretQuestions } from 'src/store/actions/actionList';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import './SecretQuestion.scss';
import ConditionalWrapper from 'src/components/ConditionalWrapper';

interface IProps {
    isShowSecretQuestionModal: boolean;
    isSecretQuestionCheckPoint: boolean;
    updateLesson: (partialData: any) => void;
}

interface IRouteProps extends Record<string, string | undefined> {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface ModalType {
    modalTitle: string;
    modelClassName?: string;
    show: boolean;
    header?: string;
    buttonText: string;
    buttonClick: string;
    isButtonDisable?: boolean;
    backdrop?: boolean | 'static';
    modelSize?: any;
    isCloseButton: boolean;
    disableBtnTooltip?: string;
}

interface UserAnswer {
    _id: string;
    key: string;
    usersAnswer: string;
    question: string;
    value: number;
}

export const SecretQuestion = ({ isShowSecretQuestionModal, isSecretQuestionCheckPoint, updateLesson }: IProps) => {
    const dispatch = useDispatch();
    const secretQuestion = useSelector((state: any) => state.secretQuestion);
    const loggedIn = useSelector((state: RootState) => state.loggedIn);
    const params = useParams<IRouteProps>();
    useEffect(() => {
        if (isShowSecretQuestionModal) {
            secretQuestionAuthentication();
        } else {
            clearModalContent();
        }
    }, [isShowSecretQuestionModal, isSecretQuestionCheckPoint, secretQuestion]);

    const [modalContent, setModalContent] = useState<ModalType>({
        buttonClick: '',
        buttonText: '',
        show: false,
        header: '',
        backdrop: 'static',
        isButtonDisable: false,
        isCloseButton: true,
        modalTitle: '',
    });
    const [modalData, setModalData] = useState<any>({});

    const handleAnswerChange = (e: any) => {
        setModalData((prevState: any) => {
            const newQuestions = [...(prevState?.questions ?? [])];
            newQuestions[(prevState?.currentSecretQuestionIndex ?? 1) - 1] = {
                ...newQuestions[(prevState?.currentSecretQuestionIndex ?? 1) - 1],
                usersAnswer: e?.target?.value,
            };

            const isButtonDisable = newQuestions.some((question) => !question.usersAnswer.trim());
            setModalContent((prevModalContent) => ({
                ...prevModalContent,
                isButtonDisable,
                disableBtnTooltip: isButtonDisable
                    ? 'You cannot proceed because some questions still need to be answered. Use the arrows to select the questions and fill in all the answers.'
                    : '',
            }));
            return {
                ...prevState,
                questions: newQuestions,
            };
        });
    };

    const handleCurrentIndex = (index: number) => {
        setModalData((prevState: any) => {
            return {
                ...prevState,
                currentSecretQuestionIndex: index,
            };
        });
    };

    const updateSecretQuestionsSubmittedAt = (response: Date | null) => {
        dispatch(
            setLoggedIn({
                ...loggedIn,
                user: {
                    ...loggedIn.user,
                    secretQuestionsSubmittedAt: response,
                },
            }),
        );
    };

    const updateSecretQuestionsInRedux = (questions: UserAnswer[]) => {
        dispatch(setSecretQuestions(questions));
    };

    const handleSubmit = async () => {
        const questions = [...(modalData?.questions ?? [])];

        const payload = questions.map((que: UserAnswer) => {
            return {
                key: que.key,
                answer: que.usersAnswer,
            };
        });

        const { success, response, message } = await Api.call('POST', `/users/secretQuestions/answer`, payload);
        if (success) {
            updateSecretQuestionsSubmittedAt(response);
            const questionsWithoutAnswers = questions.map((q) => ({
                ...q,
                usersAnswer: '',
                _id: '',
            }));
            updateSecretQuestionsInRedux([...questionsWithoutAnswers]);

            EventBus.dispatch('toast', {
                type: 'success',
                message,
            });

            if (isSecretQuestionCheckPoint) {
                const modalContent = await getVerifySecretQuestionsModalContent();
                if (modalContent) {
                    setModalContent((prevState) => {
                        return {
                            ...prevState,
                            ...modalContent,
                        };
                    });
                }
            } else {
                clearModalContent();
            }
        }
    };

    const getSecretQuestionsContent = () => {
        const { questions = [], currentSecretQuestionIndex = 1 } = modalData;
        return [
            <p key='first-para'>{`This is your first time enrolling in a course!`}</p>,
            <p key='second-para'>
                {`We will use `}
                <b>{`11 secret questions. `}</b>
                {` to keep your account safe and verify your identity. Make sure your answers are unique and easy for you to remember.
                 During the course, you might be asked one of these questions, and you will need to give the correct answer
                  to prove it’s really you. `}
            </p>,
            <div key='input-from-user'>
                <Row>
                    <Col md={3} className='input-label'>
                        <b>Secret question {currentSecretQuestionIndex}</b>
                    </Col>
                    <Col>
                        <select
                            className='secret-questions-select'
                            value={currentSecretQuestionIndex}
                            onChange={({ target: { value } }) => {
                                handleCurrentIndex(parseInt(value));
                            }}
                        >
                            {questions.map((item: UserAnswer) => {
                                return (
                                    <option key={item.key} value={item.value}>
                                        {item.question}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={3} className='input-label'>
                        <label htmlFor='user-input'>
                            <b>Secret answer {currentSecretQuestionIndex}</b>
                        </label>
                    </Col>
                    <Col>
                        <input
                            type='text'
                            name='user-input'
                            value={questions[currentSecretQuestionIndex - 1]?.usersAnswer}
                            onChange={handleAnswerChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleCurrentIndex(Math.min(questions.length, currentSecretQuestionIndex + 1));
                                }
                            }}
                            maxLength={100}
                        ></input>
                    </Col>
                </Row>
            </div>,
            <Row key='switcher'>
                <Switcher
                    current={currentSecretQuestionIndex}
                    total={questions.length}
                    onPrev={(index: number) => {
                        handleCurrentIndex(index);
                    }}
                    onNext={(index: number) => {
                        handleCurrentIndex(index);
                    }}
                />
            </Row>,
        ];
    };

    const getSecretQuestionsModalContent = async () => {
        const questions = await getSecretQuestions();

        const modelData = {
            questions: [...questions],
            currentSecretQuestionIndex: 1,
        };

        if (modalData?.randomQuestion) {
            modelData.currentSecretQuestionIndex = modalData?.randomQuestion?.value;
        }

        if (modelData.questions?.length) {
            const isButtonDisable = modelData.questions.some((question) => !question.usersAnswer.trim());
            return {
                modalContent: {
                    modelClassName: 'collect-secret-questions-answers',
                    backdrop: false,
                    show: true,
                    header: `Secure your account with secret questions`,
                    modalTitle: 'getSecretQuestionsContent',
                    buttonText: 'Confirm secret questions',
                    buttonClick: 'handleSubmit',
                    isButtonDisable: isButtonDisable,
                    modelSize: 'xl',
                    isCloseButton: false,
                    disableBtnTooltip: isButtonDisable
                        ? 'You cannot proceed because some questions still need to be answered. Use the arrows to select the questions and fill in all the answers.'
                        : '',
                },
                modalData: { ...modelData, randomQuestion: null },
            };
        } else {
            return null;
        }
    };

    const verifySecretQuestionSubmit = async () => {
        setModalContent((prevState) => {
            return {
                ...prevState,
                isButtonDisable: true,
            };
        });

        const randomQuestion = { ...modalData?.randomQuestion };

        const { courseId, lessonId } = params;

        const payload = {
            key: randomQuestion?.key,
            answer: randomQuestion?.usersAnswer?.trim(),
            userCourseId: courseId,
            userLessonId: lessonId,
        };

        const { success, response } = await Api.call('POST', `/users/secretQuestions/verifyAnswer`, payload);

        if (success && response) {
            if (response?.isCorrectAnswer) {
                updateLesson({ secretQuestionsValidated: true });
                clearModalContent();
            } else {
                handleRandomQuestionWrongAnswer();
            }
        }
    };

    const handleRandomQuestionAnswer = (e: any) => {
        setModalData((prevState: any) => {
            const randomQuestion = { ...prevState?.randomQuestion };
            randomQuestion.usersAnswer = e?.target?.value;
            randomQuestion.error = '';
            const isButtonDisable = !randomQuestion.usersAnswer.trim();

            setModalContent((prevModelContent) => {
                return {
                    ...prevModelContent,
                    isButtonDisable,
                };
            });

            return {
                ...prevState,
                randomQuestion: { ...randomQuestion },
            };
        });
    };

    const handleRandomQuestionWrongAnswer = () => {
        setModalData((prevState: any) => {
            const randomQuestion = { ...prevState.randomQuestion };
            randomQuestion.error = 'Provided answer does not match, try again!';
            return {
                ...prevState,
                randomQuestion: randomQuestion,
            };
        });
    };

    const getNewRandomQuestion = () => {
        setModalData((prevState: any) => {
            const previousRandomQuestion = { ...prevState?.randomQuestion };
            const questions = [...(prevState?.questions ?? [])];
            let randomQuestion = { ...previousRandomQuestion };
            while (previousRandomQuestion.key === randomQuestion.key) {
                const randomValue = Utility.getRandomValWithMaxValue((questions ?? 0).length);
                randomQuestion = { ...questions?.[randomValue - 1], usersAnswer: '' };
            }

            return {
                ...prevState,
                randomQuestion: { ...randomQuestion },
            };
        });
    };

    const handleResetAllSecretQuestions = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Reset secret questions',
            body: `Please confirm do you want to reset all your secret questions`,
            confirm: {
                text: 'Yes',
                action: async () => {
                    const { success, response } = await Api.call('PATCH', `/users/secretQuestions/forgot`);
                    if (success && response) {
                        updateSecretQuestionsSubmittedAt(null);
                        updateSecretQuestionsInRedux(response);
                    }

                    const data = await getSecretQuestionsModalContent();

                    if (data) {
                        setModalData((prevState: any) => {
                            return {
                                ...prevState,
                                ...data.modalData,
                            };
                        });
                        setModalContent((prevState) => {
                            return {
                                ...prevState,
                                ...data.modalContent,
                            };
                        });
                    }
                },
            },
            cancel: {
                text: 'No',
                action: () => {},
            },
        });
    };

    const callFunction = (title: string): any => {
        switch (title) {
            case 'getVerifySecretQuestionsContent': {
                return getVerifySecretQuestionsContent;
            }
            case 'getSecretQuestionsContent': {
                return getSecretQuestionsContent;
            }
            case 'handleSubmit': {
                return handleSubmit;
            }
            case 'verifySecretQuestionSubmit': {
                return verifySecretQuestionSubmit;
            }
            default:
                return () => {
                    return title;
                };
        }
    };

    const getVerifySecretQuestionsContent = () => {
        const question = { ...modalData.randomQuestion };

        return [
            <p key='first-para'>{`To confirm your identity, please answer one of the 11 secret questions you set up when you started the course.`}</p>,
            <Row key='question-row' className='random-question-row'>
                <Col>
                    <b>{question?.question}</b>
                </Col>
                <Col className='try-another-secret-question-btn-wrapper-col'>
                    <button onClick={getNewRandomQuestion} className={`link-btn try-another-secret-question-btn`}>
                        Try another question
                    </button>
                </Col>
            </Row>,
            <div key='input-from-user'>
                <Row>
                    <Col>
                        <input
                            type='text'
                            name='user-input'
                            value={question?.usersAnswer}
                            onChange={handleRandomQuestionAnswer}
                            maxLength={100}
                        ></input>
                        {question?.error && (
                            <small className={'mt-2 form-builder__error-color'}>{question?.error}</small>
                        )}
                    </Col>
                </Row>
            </div>,
            <Row key='reset'>
                <Col>
                    <button className={`link-btn forger-secret-question-btn`} onClick={handleResetAllSecretQuestions}>
                        Forget secret answer ?
                    </button>
                </Col>
            </Row>,
        ];
    };

    const getSecretQuestions = async () => {
        let questions: UserAnswer[] = [...(secretQuestion?.questions ?? [])];

        if (!questions.length) {
            const { success, response } = await Api.call('GET', `/users/secretQuestions/all`);
            if (success && response) {
                questions = response;
                updateSecretQuestionsInRedux(response);
            }
        }

        return questions;
    };

    const getVerifySecretQuestionsModalContent = async () => {
        const questions = await getSecretQuestions();

        const modelData = {
            questions: [...questions],
            randomQuestion: {
                question: '',
                key: '1',
                value: 1,
                usersAnswer: '',
            },
        };

        if ((modelData?.questions ?? []).length) {
            const randomValue = Utility.getRandomValWithMaxValue(modelData.questions.length);
            modelData.randomQuestion = modelData.questions[randomValue - 1];

            return {
                modalContent: {
                    modelClassName: 'verify-secret-questions-answer',
                    backdrop: false,
                    show: true,
                    header: `Answer a secret question to continue`,
                    modalTitle: 'getVerifySecretQuestionsContent',
                    buttonText: 'Submit',
                    buttonClick: 'verifySecretQuestionSubmit',
                    isButtonDisable: true,
                    modelSize: 'lg',
                    isCloseButton: false,
                },
                modalData: modelData,
            };
        } else {
            return null;
        }
    };

    const secretQuestionAuthentication = async () => {
        let data: { modalContent: ModalType; modalData: any } | null = null;
        if (!loggedIn.user?.secretQuestionsSubmittedAt) {
            data = await getSecretQuestionsModalContent();
        } else if (isSecretQuestionCheckPoint) {
            data = await getVerifySecretQuestionsModalContent();
        }

        if (data) {
            setModalData((prevState: any) => {
                return {
                    ...prevState,
                    ...data?.modalData,
                };
            });
            setModalContent((prevState) => {
                return { ...prevState, ...data?.modalContent };
            });
        }

        return modalContent;
    };

    const clearModalContent = () => {
        setModalContent({
            show: false,
            header: '',
            modalTitle: '',
            buttonText: '',
            buttonClick: '',
            modelSize: undefined,
            modelClassName: '',
            isButtonDisable: false,
            backdrop: 'static',
            isCloseButton: true,
        });
        setModalData({});
    };

    const getModalButton = () => {
        return (
            <Button
                variant='primary'
                onClick={modalContent?.isButtonDisable ? () => {} : callFunction(modalContent.buttonClick)}
                disabled={modalContent?.isButtonDisable ?? false}
            >
                {modalContent.buttonText}
            </Button>
        );
    };

    return (
        <div>
            <Modal
                className={`secret-question-modal ${!modalContent?.backdrop ? 'modal-backdrop' : ''} ${
                    modalContent.modelClassName ?? ''
                }`}
                show={modalContent.show}
                onHide={clearModalContent}
                backdrop={modalContent?.backdrop ?? 'static'}
                size={['lg', 'sm', 'xl'].includes(modalContent?.modelSize) ? modalContent?.modelSize : undefined}
            >
                {modalContent.header && (
                    <Modal.Header closeButton={modalContent.isCloseButton}>
                        <h4>{modalContent.header}</h4>
                    </Modal.Header>
                )}
                <Modal.Body>{callFunction(modalContent.modalTitle)()}</Modal.Body>
                <Modal.Footer>
                    {modalContent.disableBtnTooltip ? (
                        <ConditionalWrapper
                            condition={modalContent?.isButtonDisable && modalContent.disableBtnTooltip}
                            wrapper={(children: any) => (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`cancel-tooltip-text`} className='abs-tooltip'>
                                            {modalContent.disableBtnTooltip}
                                        </Tooltip>
                                    }
                                >
                                    {children}
                                </OverlayTrigger>
                            )}
                        >
                            <div>{getModalButton()}</div>
                        </ConditionalWrapper>
                    ) : (
                        getModalButton()
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};
