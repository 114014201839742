import React, { Component } from 'react';
import { RouteComponentProps } from 'src/hoc/withRouter';
import StepCheckout from './StepCheckout/StepCheckout';
import SinglePageCheckout from './SinglePageCheckout/SinglePageCheckout';
import { Api, EventBus, Utility } from 'src/helpers/new';
import CheckoutContext from './CheckoutContext';
import { first, isNil } from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import {
    thoughtMetricScript,
    facebookPixelScript,
    googleAdsScript,
    googleAnalyticsScript,
    bingScript,
} from 'src/helpers/externalScript';
import { getState, removeState, setState } from 'src/helpers/localStorage';
import {
    AdditionalData,
    BillingAddress,
    CartData,
    Contact,
    Package,
    PkgTermsAgreement,
    Tab,
} from './CheckoutTypeHelper';
import { commonTimeout } from 'src/helpers/commonTimeout';
import { CheckoutSurveyPrompt } from './Survey/CheckoutSurveyPrompt';
import { SurveyBlockerForm } from './Survey/SurveyBlockerForm';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import { IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import './Checkout.scss';

interface IProps extends RouteComponentProps {
    loggedIn: IUserLoggedIn;
}

export interface IState {
    status: string;
    billingAddress: BillingAddress;
    contact: Contact;
    user: any;
    pkgTermsAgreement?: PkgTermsAgreement[];
    currentTab: number;
    tabs: Tab[];
    upsellSuggestions: any;
    topSuggested: any;
    isDisplaySuggestion: boolean;
    isPriceUpdate: boolean;
    termsAndCondition: string;
    showTermsAndCondition: boolean;
    packages: any;
    title: string;
    upsells: any;
    state: string;
    couponCode: string | null;
    isAdminPreview: boolean;
    price: number;
    tax: number;
    paymentPackages: { title: string }[];
    isSingleCheckout: boolean;
    validation: {
        emailMessage: string;
        zipcodeMessage: string;
        inVaild: boolean;
    };
    surveyConfig: {
        discountCode: string;
        discountHeading: string;
        discountText: string;
        surveyTriggerDuration: number;
        thoughtMetricSurvey: boolean;
    };
    previousX: any;
    previousY: any;
    isSurveyModalShown: boolean;
    isSurveyModalAction: boolean;
}

class Checkout extends Component<IProps, IState> {
    isAdminPreview = false;
    packageId = '';
    location = '';
    source = '';
    campaign = '';
    medium = '';
    timestamp = '';
    bundleId = '';
    isUpsell = false;
    isBundle = false;
    couponCode = '';

    state: IState = {
        status: 'LOADING',
        billingAddress: {
            state: 'none',
            country: 'US',
            streetLines: [''],
            zipCode: '',
        },
        contact: {
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
        },
        user: {},
        currentTab: 0,
        tabs: [
            { title: 'Your Order', completed: true },
            { title: 'Contact Info', completed: false },
            { title: 'Billing Info', completed: false },
            { title: 'Payment', completed: false },
        ],
        upsellSuggestions: [],
        topSuggested: {},
        isDisplaySuggestion: false,
        isPriceUpdate: false,
        termsAndCondition: '',
        showTermsAndCondition: false,
        packages: [],
        upsells: [],
        state: '',
        title: '',
        isAdminPreview: false,
        couponCode: null,
        paymentPackages: [],
        price: 0,
        tax: 0,
        isSingleCheckout: false,
        validation: {
            emailMessage: '',
            zipcodeMessage: '',
            inVaild: false,
        },
        surveyConfig: {
            discountCode: '',
            discountHeading: '',
            discountText: '',
            surveyTriggerDuration: 45,
            thoughtMetricSurvey: false,
        },
        previousX: null,
        previousY: null,
        isSurveyModalShown: false,
        isSurveyModalAction: false,
    };

    get getCartId() {
        const cartId = this.isAdminPreview ? getState('reuPreviewCheckoutCartId') : getState('reuCheckoutCartId');
        let id;
        if (this.isAdminPreview) {
            id = cartId;
        } else {
            const userId = getState('user')?._id;
            id = userId ?? cartId;
        }
        return { cartId, id };
    }

    get getIsLoggedInFlag() {
        if (this.isAdminPreview) {
            return false;
        } else {
            return !!getState('user')?._id;
        }
    }

    async componentDidMount() {
        this.initializeQueryParams();
        this.initializeCart();
        this.initializeScripts();
        this.trackPageView();
        await this.checkoutSurvey();
    }

    initializeQueryParams() {
        const query = new URLSearchParams(this.props.location.search);
        this.isAdminPreview = query.get('isAdminPreview') === 'true';

        const params = ['packageId', 'location', 'utm_source', 'utm_campaign', 'utm_medium', 'bundleId', 'couponCode'];
        [this.packageId, this.location, this.source, this.campaign, this.medium, this.bundleId, this.couponCode] =
            params.map((param) => query.get(param) ?? '');

        this.timestamp = query.has('cookietimestamp')
            ? DateTimeFormatHelper.userTimeZoneToUTC(query.get('cookietimestamp'), 'YYYYMMDDTHHmmssZ')
            : '';

        this.isUpsell = query.get('isUpsell') === 'true';
        this.isBundle = !!this.bundleId;
    }

    async initializeCart() {
        const { cartId } = this.getCartId;
        const newCartData = this.props.location.state || this.packageId || this.bundleId;

        EventBus.onWindow('message', this.handleWindowMessage);

        if (!cartId && !newCartData) {
            this.setState({ status: 'EMPTY' });
            return;
        }

        if (!cartId && newCartData) {
            await this.createCartFromPackage();
        } else {
            await this.loadCartData();
            if (cartId && newCartData) {
                this.setState(
                    (prevState: any) => {
                        return {
                            packages: prevState?.package ?? [],
                            ...prevState,
                        };
                    },
                    async () => {
                        await this.updateCart();
                        await this.getPrice();
                    },
                );
            } else if (!cartId && !newCartData) {
                EventBus.dispatch('toast', {
                    type: 'error',
                    message: 'Your Cart is Empty. Go back and Add something to your Cart.',
                });
                this.props.navigate('/');
            }
        }

        removeState('cartPackageId');
        window.history.replaceState(null, '');
    }

    initializeScripts() {
        if (!this.isAdminPreview) {
            thoughtMetricScript();
            facebookPixelScript();
            googleAdsScript();
            googleAnalyticsScript();
            bingScript();
        }
    }

    trackPageView() {
        if (window.thoughtmetric && typeof window.thoughtmetric === 'function') {
            window.thoughtmetric('pageview');
        }
        if (window.fbq && typeof window.fbq === 'function') {
            window.fbq('track', 'PageView');
            window.fbq('track', 'InitiateCheckout');
        }
        EventBus.on('check-coupon-code-used', this.checkedCouponCodeBeforePayment);
    }

    checkoutSurvey = async () => {
        if (isNil(this.props?.loggedIn?.token)) {
            const { success, response } = await Api.call('GET', '/settings/checkout');
            if (success) {
                this.setState({
                    surveyConfig: response,
                });

                if (response.surveyTriggerDuration <= 0) return;
                commonTimeout.startTimer(
                    'checkoutSurvey',
                    () => {
                        document.addEventListener('mousemove', this.handleMouseMove);
                    },
                    response.surveyTriggerDuration * 1000,
                );
            }
        }
    };

    isNearCloseButton = (x: any, y: any, browser: any) => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const radius = Math.min(windowWidth, windowHeight) * 0.1;

        let distance;
        if (browser === 'Safari') {
            if (x > windowWidth / 2) {
                return false;
            }
            distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        } else {
            distance = Math.sqrt(Math.pow(windowWidth - x, 2) + Math.pow(y, 2));
        }

        return distance <= radius;
    };

    isMovingTowardsCloseButton = (x: any, y: any, previousX: any, previousY: any, browser: any) => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (previousX === null || previousY === null) {
            return false;
        }

        const moveX = x - previousX;
        const moveY = y - previousY;

        if (browser === 'Safari') {
            const radius = Math.min(windowWidth, windowHeight) * 0.2;
            const distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            return distance <= radius && moveX < 0 && moveY < 0;
        } else {
            return moveX > 0 && moveY < 0 && y < windowHeight * 0.2 && x > windowWidth * 0.8;
        }
    };

    handleMouseMove = (e: any) => {
        if (!isNil(this.props?.loggedIn?.token)) return;

        if (this.state.isSurveyModalAction) {
            const browser = this.detectBrowser();
            const { clientX: x, clientY: y } = e;
            const { previousX, previousY } = this.state;

            const isNear = this.isNearCloseButton(x, y, browser);
            const isMovingTowards = this.isMovingTowardsCloseButton(x, y, previousX, previousY, browser);

            if (isNear || isMovingTowards) {
                this.triggerHesitantCustomerAction();
            }

            this.setState({ previousX: x, previousY: y });
        }
    };

    detectBrowser = () => {
        if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf('Chrome') != -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf('Safari') != -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf('Firefox') != -1) {
            return 'Firefox';
        } else {
            return 'Unknown';
        }
    };

    triggerHesitantCustomerAction = () => {
        console.log('Hesitant customer detected!', this.state.isSurveyModalShown);
        if (!this.state.isSurveyModalShown) {
            this.setState({ isSurveyModalShown: true });
        }
    };

    componentWillUnmount() {
        const { id } = this.getCartId;
        if (isNil(this.props?.loggedIn?.token)) {
            commonTimeout.clearTimer('checkoutSurvey');
            document.removeEventListener('mousemove', this.handleMouseMove);
        }

        window.socket.emit('leave-tab', {
            id: id,
            lastTab: this.state.currentTab,
            isLoggedIn: this.getIsLoggedInFlag,
        });
    }

    handleWindowMessage = async (event: any) => {
        if (event.origin !== process.env.REACT_APP_URL) return;
        const { updateData } = event.data;

        if (!updateData?.isPacakgeUpdated) return;

        if (updateData?.isPacakgeUpdated) {
            await this.loadCartData();
        }
    };

    checkedCouponCodeBeforePayment = async (event: any) => {
        await this.getPrice(true);
    };

    createCartFromPackage = async (): Promise<void> => {
        const ERData = Object.values((this.props?.location?.state as any) || []);
        const ERArr: any = ERData.map((course: any) => course.hasOwnProperty('repurchase'));

        const createCartData: any = {
            packagePurchaseType: this.getPackageType(ERArr) || 'new',
            courses: ERData,
            isUpsell: this.isUpsell,
            isBundle: this.isBundle,
            userId: getState('user')?._id,
            device: Utility.isMobileView() ? 'mobile' : 'desktop',
            timeZone: DateTimeFormatHelper.getUserTimeZone(),
            ...(this.isUpsell && { upsellLocation: this.location }),
            ...(this.couponCode && { couponCode: this.couponCode.toUpperCase() }),
            ...(this.source &&
                this.timestamp && {
                    source: this.source,
                    campaign: this.campaign,
                    medium: this.medium,
                    timestamp: this.timestamp,
                }),
        };

        const { success, response } = await Api.call(
            'post',
            `checkout/cart/create/${this.bundleId || this.packageId || getState('cartPackageId')}`,
            createCartData,
        );

        if (success) {
            const { cart } = response;
            setState(this.isAdminPreview ? 'reuPreviewCheckoutCartId' : 'reuCheckoutCartId', cart._id);
            this.props.navigate(this.isAdminPreview ? '/checkout?isAdminPreview=true' : '/checkout', { replace: true });
            await this.loadCartData();
        }
    };

    loadCartData = async () => {
        const { id } = this.getCartId;
        const isLoggedIn = this.getIsLoggedInFlag;

        const { response } = await Api.call(
            'get',
            `checkout/cart/${id}?location=${this.location}&isLoggedIn=${isLoggedIn}`,
        );

        const { cart, user } = response;

        let additional;
        if (!cart.singlePageCheckout) {
            const tabs = this.state.tabs.map((tab: Tab, index: number) => {
                let completed = tab.completed;
                if (index === 1) {
                    completed = !!cart?.contact;
                } else if (index === 2) {
                    completed = !!cart?.billingAddress;
                }
                return {
                    ...tab,
                    completed: completed,
                };
            });

            const tabIndex = cart?.isOldCart ? 0 : cart?.currentTab || 0;
            additional = {
                tabs,
                currentTab: parseInt(tabIndex),
                status: 'READY',
                user,
                isSingleCheckout: cart.singlePageCheckout,
                isSurveyModalAction: cart?.showSurvey ?? false,
            };

            window.socket.emit('enter-tab', {
                id,
                isLoggedIn,
            });
        } else {
            additional = {
                status: 'READY',
                user,
                isSingleCheckout: cart.singlePageCheckout,
                isSurveyModalAction: cart?.showSurvey ?? false,
            };
        }

        if (user) {
            const contact = {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            };

            const billingAddress = {
                country: user?.billingAddress?.country || this.state.billingAddress?.country,
                firstName: user?.billingAddress?.firstName || user.firstName,
                lastName: user?.billingAddress?.lastName || user?.lastName,
                state: user?.billingAddress?.state || user?.stateBilling || '',
                town: user?.billingAddress?.town || user?.townCityBilling || '',
                zipCode: user?.billingAddress?.zipCode || user?.zipCodeBilling || '',
                streetLines: user?.billingAddress?.streetLines?.filter((line: string) => line?.trim()) || [
                    user.addressLineOneBilling || '',
                ],
            };

            this.setState({
                user: user,
                contact,
                billingAddress,
                isSurveyModalAction: cart?.showSurvey ?? false,
            });
        }

        this.setCartData(cart, additional);
    };

    getPackageType = (ERArr: any) => {
        const hasRepurchase = ERArr.includes(true);
        const hasExtension = ERArr.includes(false);

        if (hasRepurchase && hasExtension) {
            return 'extension_repurchase';
        }
        if (hasRepurchase) {
            return 'repurchase';
        }
        if (hasExtension) {
            return 'extension';
        }
        return 'new';
    };

    setCartData = (cartData: CartData, additional: AdditionalData = {}, callback?: () => void) => {
        const packages: Package[] = (cartData.packages as Package[]) ?? [];
        const courses: any[] = cartData.courses ?? [];
        const allCartData: Package[] = [...packages, ...courses];

        this.setState((prevState) => {
            const contact = cartData.contact ?? prevState.contact;
            const billingAddress = cartData.billingAddress ?? prevState.billingAddress;

            const pkgTermsAgreement: PkgTermsAgreement[] = [];
            const pkgTermsAgreementPrevious: PkgTermsAgreement[] = prevState.pkgTermsAgreement ?? [];
            let totalPrice = 0;

            packages.forEach((pkg) => {
                const index = pkgTermsAgreementPrevious.findIndex((x) => x._id === pkg._id);
                if (pkg.termsAndConditions && pkg.termsAndConditionsHeader) {
                    pkgTermsAgreement.push({
                        _id: pkg._id,
                        title: pkg.title ?? '',
                        termsAndConditions: pkg.termsAndConditions,
                        checked: index === -1 ? false : pkgTermsAgreementPrevious[index].checked,
                        termsAndConditionsHeader: pkg.termsAndConditionsHeader,
                    });
                }
            });

            allCartData.forEach((pkg) => {
                totalPrice += pkg.discountedPrice ?? pkg.price ?? 0;
            });

            return {
                packages: allCartData,
                title: packages[0]?.title ?? '',
                upsells: cartData.upsells ?? [],
                state: cartData.state,
                billingAddress,
                contact,
                couponCode: cartData.couponCode ?? null,
                pkgTermsAgreement,
                isAdminPreview: this.isAdminPreview,
                currentTab: cartData.currentTab,
                price: totalPrice,
                paymentPackages: packages.map((item: any) => item.packageId) ?? [],
                ...additional,
            };
        }, callback);
    };

    updateCart = async (
        cartData?: CartData,
        onError?: (error: any) => void,
        onSuccess?: (response: any) => void,
        isCouponCode?: boolean,
    ): Promise<void> => {
        const { cartId, id } = this.getCartId;
        if (!cartId || (typeof cartId === 'string' && cartId.length !== 24)) {
            this.clearLocalCartId();
        }

        const newData = this.props.location.state as any;
        const updatedCartData: CartData = {
            packages: this.state.packages.filter((pkg: Package) => 'packagePurchaseType' in pkg),
            courses: this.state.packages.filter((pkg: Package) => 'purchaseType' in pkg),
            device: Utility.isMobileView() ? 'mobile' : 'desktop',
            ...cartData,
        };

        if (!cartData && newData) {
            const updatedCourses = updatedCartData.courses ? [...updatedCartData.courses] : [];
            const updatedCoursesIds = updatedCourses.map((course) => course._id);
            const newUpdatedData = Object.values(newData);

            newUpdatedData.forEach((newDataItem: any) => {
                const courseIndex = updatedCoursesIds.indexOf(newDataItem._id);
                if (courseIndex > -1) {
                    updatedCourses[courseIndex] = newDataItem;
                } else {
                    updatedCourses.push(newDataItem);
                }
            });

            updatedCartData.courses = updatedCourses;
            updatedCartData.currentTab = 0;
        } else if (!cartData && (this.packageId || this.bundleId)) {
            const newPackage = await this.managePackageBasedOnBundleOrUpsell(
                updatedCartData.packages ? [...(updatedCartData.packages as Package[])] : [],
            );

            updatedCartData.packages = newPackage;
            updatedCartData.currentTab = 0;

            if (this.couponCode) {
                updatedCartData.couponCode = this.couponCode.toUpperCase();
            }
        }

        const { success, response, raw } = await Api.call(
            'patch',
            `checkout/cart/${id}?location=${this.location}&isLoggedIn=${this.getIsLoggedInFlag}`,
            updatedCartData,
        );

        if (success) {
            const allCartData = [...response.packages, ...response.courses];
            if (this.bundleId || this.packageId) {
                const checkoutPath = this.isAdminPreview ? '/checkout?isAdminPreview=true' : '/checkout';
                this.props.navigate(checkoutPath, { replace: true });
            }

            this.setCartData(
                response,
                { status: allCartData.length > 0 ? 'READY' : 'EMPTY', isPriceUpdate: cartData?.isPriceUpdate ?? false },
                () => {
                    if (onSuccess) {
                        onSuccess(raw);
                    }
                },
            );
        } else {
            if (onError) {
                onError(raw);
            }
            if (!isCouponCode) {
                this.clearLocalCartId();
            }
        }
    };

    updateCheckoutState = (state: IState) => {
        this.setState({
            ...state,
        });
    };

    switchTab = async (tabIndex: number, complete = false): Promise<void> => {
        const tabs = [...this.state.tabs];
        if (complete) {
            tabs[this.state.currentTab].completed = true;
        }

        if (tabIndex === 3) {
            await this.getUpsellSuggestion();
        }

        const { id } = this.getCartId;

        window.socket.emit(
            'leave-tab',
            {
                id: id,
                lastTab: tabIndex === this.state.currentTab ? this.state.currentTab - 1 : this.state.currentTab,
                isLoggedIn: this.getIsLoggedInFlag,
            },
            this.leaveTabCallback,
        );

        this.setState({ currentTab: tabIndex, tabs });
    };

    updateTopSuggestion = (): void => {
        const { upsellSuggestions, topSuggested } = this.state;
        const suggestionList = upsellSuggestions.filter((v: any) => v._id !== topSuggested._id);

        if (suggestionList.length > 0) {
            this.setState({
                upsellSuggestions: suggestionList,
                topSuggested: suggestionList[0],
            });
        } else {
            this.setState({
                isPriceUpdate: true,
                isDisplaySuggestion: false,
            });
        }
    };

    getUpsellSuggestion = async () => {
        this.setState({
            status: 'LOADING',
        });

        const { id } = this.getCartId;
        const { success, response } = await Api.call(
            'get',
            `checkout/cart/${id}/upsells?location=suggestion&isLoggedIn=${this.getIsLoggedInFlag}`,
        );

        if (success) {
            this.setState({
                isDisplaySuggestion: response.length > 0,
                upsellSuggestions: response,
                status: 'READY',
                topSuggested: first(response),
            });
        }
    };

    leaveTabCallback = (data: { success: boolean }) => {
        if (data.success) {
            const { id } = this.getCartId;
            window.socket.emit('enter-tab', {
                id: id,
                isLoggedIn: this.getIsLoggedInFlag,
            });
        }
    };

    clearLocalCartId = () => {
        if (this.isAdminPreview) {
            localStorage.removeItem('reuPreviewCheckoutCartId');
        } else {
            localStorage.removeItem('reuCheckoutCartId');
        }

        window.location.reload();
    };

    getBundle = async (bundleId: string) => {
        return await Api.call('get', `bundles/${bundleId}`);
    };

    getUpsell = async (upsellId: string) => {
        return await Api.call('get', `upsells/${upsellId}`);
    };

    managePackageBasedOnBundleOrUpsell = async (existingPkgs: Package[] = []): Promise<Package[]> => {
        let updatedPkgs = [...existingPkgs];

        type Response = {
            success: boolean;
            response: {
                packages?: { packageId: string }[];
                upsoldPackageId?: string;
            };
        };

        if (this.bundleId && this.isBundle) {
            const { success, response } = (await this.getBundle(this.bundleId)) as Response;

            if (success && response.packages && response.packages.length) {
                const bundlePackageIds = response.packages.map((item) => item.packageId);

                const oldPkgs = existingPkgs.filter((item) => !bundlePackageIds.includes(item.packageId));
                const newPkgs = response.packages.map((pkg) => ({
                    _id: pkg.packageId,
                    bundleId: this.bundleId,
                    packageId: pkg.packageId,
                    isUpsell: false,
                    isBundle: true,
                    packagePurchaseType: 'new',
                    source: this.source,
                    campaign: this.campaign,
                    medium: this.medium,
                    timestamp: this.timestamp,
                }));

                updatedPkgs = [...oldPkgs, ...newPkgs];
            }
        } else if (this.packageId && this.isUpsell) {
            const { success, response } = (await this.getUpsell(this.packageId)) as Response;

            if (success && response.upsoldPackageId) {
                let isToPushPackage = true;

                const newPkg = {
                    _id: this.packageId,
                    packageId: response.upsoldPackageId,
                    isUpsell: true,
                    isBundle: false,
                    packagePurchaseType: 'new',
                    upsellLocation: this.location,
                };

                updatedPkgs = updatedPkgs.map((pkg) => {
                    if (pkg.packageId === response.upsoldPackageId) {
                        isToPushPackage = false;

                        if (!pkg.isBundle) {
                            return { ...newPkg };
                        } else {
                            EventBus.dispatch('alert', {
                                type: 'error',
                                title: 'Package Already Exists!',
                                message: 'Same Package already added in cart!',
                            });
                        }
                    }
                    return pkg;
                });

                if (isToPushPackage) {
                    updatedPkgs.push({ ...newPkg });
                }
            }
        } else if (this.packageId && !this.isUpsell && !this.isBundle) {
            if (!existingPkgs.some((pkg) => pkg.packageId === this.packageId)) {
                updatedPkgs = [
                    ...existingPkgs,
                    {
                        _id: this.packageId,
                        packagePurchaseType: 'new',
                        isUpsell: this.isUpsell,
                        isBundle: this.isBundle,
                        packageId: this.packageId,
                        source: this.source,
                        campaign: this.campaign,
                        medium: this.medium,
                        timestamp: this.timestamp,
                    },
                ];
            } else {
                EventBus.dispatch('alert', {
                    type: 'error',
                    title: 'Package Already Exists!',
                    message: 'Same Package already added in cart!',
                });
            }
        } else {
            // Handle the unexpected scenario
        }

        return updatedPkgs;
    };

    pkgTermsConditionsCheckboxToggle = (_id: string) => {
        const packages = this.state.pkgTermsAgreement ? [...this.state.pkgTermsAgreement] : [];
        const pkgIndex = packages.findIndex((x) => x._id === _id);

        if (pkgIndex !== -1) {
            packages[pkgIndex] = { ...packages[pkgIndex], checked: !packages[pkgIndex].checked };
            this.setState({ pkgTermsAgreement: packages });
        }
    };

    clearModalContent = () => {
        this.setState({
            termsAndCondition: '',
            showTermsAndCondition: !this.state.showTermsAndCondition,
        });
    };

    onSetTermsAndConditions = (value: string) => {
        this.setState({
            termsAndCondition: value,
            showTermsAndCondition: true,
        });
    };

    getPrice = async (isForStripe?: boolean): Promise<{ success: boolean; response: any }> => {
        const query = new URLSearchParams(this.props.location.search);
        const location = query.get('location');

        const { id } = this.getCartId;

        const { success, response } = await Api.call(
            'POST',
            `checkout/cart/${id}/getPrice?location=${location}&isLoggedIn=${this.getIsLoggedInFlag}`,
            {
                singlePageCheckout: this.state.isSingleCheckout ?? false,
                billingAddress: this.state?.billingAddress ?? {},
                contact: this.state.contact ?? {},
            },
        );

        if (success) {
            EventBus.dispatch('set-tax-value', response.tax);
            const packages = response.cart.packages.map((item: any) => item.packageId);
            this.setState({
                price: response.price,
                tax: response.tax,
                paymentPackages: packages,
                packages: response.cart?.packages.concat(response.cart?.courses),
                couponCode: response.cart?.couponCode,
            });
        }
        if (isForStripe) {
            EventBus.dispatch('verified-coupon-code', success);
        }
        return { success, response };
    };

    getOrderDetails = () => {
        const { packages } = this.state;
        let totalPrice = 0;
        let couponDiscount = 0;
        let promoDiscount = 0;
        let subTotal = 0;

        packages.map((pkg: any) => {
            subTotal += pkg.price;
            totalPrice += pkg.discountedPrice ?? pkg.price;
            couponDiscount += pkg.couponDiscount ?? 0;
            promoDiscount += pkg.promoDiscount ?? 0;
        });

        return { totalPrice, couponDiscount, promoDiscount, subTotal };
    };

    onSurveySubmit = () => {
        this.setState({ isSurveyModalShown: false, isSurveyModalAction: false });

        EventBus.dispatch('action-confirmation-popup', {
            title: `Thank you!`,
            doAsyncInBackground: true,
            body: `Thank you for sharing your thoughts with us.`,
            backdrop: false,
            dismiss: () => {},
            className: 'checkout-survey-thank-you-prompt',
        });

        commonTimeout.clearTimer('checkoutSurvey');
        document.removeEventListener('mousemove', this.handleMouseMove);
    };

    onSurveyClose = async () => {
        const { cartId } = this.getCartId;
        const { success } = await Api.call('PATCH', `checkout/survey/cancel/${cartId}`);
        if (success) {
            this.setState({ isSurveyModalShown: false, isSurveyModalAction: false });
        }

        commonTimeout.clearTimer('checkoutSurvey');
        document.removeEventListener('mousemove', this.handleMouseMove);
    };

    render() {
        const {
            showTermsAndCondition,
            termsAndCondition,
            currentTab,
            status,
            state,
            isAdminPreview,
            isSingleCheckout,
            isSurveyModalShown,
        } = this.state;

        return (
            <CheckoutContext.Provider
                value={{
                    ...this.state,
                    updateCart: this.updateCart,
                    updateCheckoutState: this.updateCheckoutState,
                    switchTab: this.switchTab,
                    updateTopSuggestion: this.updateTopSuggestion,
                    getPrice: this.getPrice,
                    getOrderDetails: this.getOrderDetails,
                }}
            >
                <>
                    {isSingleCheckout ? (
                        <SinglePageCheckout
                            onSetTermsAndConditions={this.onSetTermsAndConditions}
                            pkgTermsConditionsCheckboxToggle={this.pkgTermsConditionsCheckboxToggle}
                            status={status}
                            getCartId={this.getCartId}
                            getIsLoggedInFlag={this.getIsLoggedInFlag}
                        />
                    ) : (
                        <StepCheckout
                            currentTab={currentTab}
                            isAdminPreview={isAdminPreview}
                            state={state}
                            onSetTermsAndConditions={this.onSetTermsAndConditions}
                            pkgTermsConditionsCheckboxToggle={this.pkgTermsConditionsCheckboxToggle}
                            status={status}
                        />
                    )}
                    <Modal show={showTermsAndCondition} onHide={this.clearModalContent} backdrop='static'>
                        <Modal.Header closeButton>
                            <h4>Terms and conditions</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <div id={'terms_of_service'} dangerouslySetInnerHTML={{ __html: termsAndCondition }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='primary' onClick={this.clearModalContent}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {isSurveyModalShown && (
                        <CheckoutSurveyPrompt
                            isShow={this.state.isSurveyModalShown}
                            onSubmit={this.onSurveySubmit}
                            onClose={this.onSurveyClose}
                            cartDetails={this.getCartId}
                        />
                    )}
                    <SurveyBlockerForm
                        when={isNil(this.props?.loggedIn?.token) && this.state.isSurveyModalAction}
                        onSubmit={this.onSurveySubmit}
                        onClose={this.onSurveyClose}
                        cartDetails={this.getCartId}
                    />
                </>
            </CheckoutContext.Provider>
        );
    }
}

export default withRouterAndRedux(
    Checkout,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    null,
);
