import React from 'react';
import './RouteLeavingGuard.scss';
import Prompt from 'src/hoc/Prompt';
export default class RouteLeavingGuard extends React.Component {
    state = {
        avoidRouteLeavingGuard: false,
    };

    render() {
        const { when, inExam, inQuiz, message } = this.props;
        const { avoidRouteLeavingGuard } = this.state;

        return <Prompt when={when && !avoidRouteLeavingGuard} message={message} inExam={inExam} inQuiz={inQuiz} />;
    }
}
