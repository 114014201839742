import React, { Component, RefObject } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Cropper, { Area, Point } from 'react-easy-crop';
import './CropImageModal.scss';

interface IProps {
    imgCropModalOpen: boolean;
    displayUploadUrl: string;
    handleCropModal: (isopen: boolean, croppedImage: File) => void;
    uploadFilePath: File | null;
    onCloseProfileModal: () => void;
}
interface IState {
    zoom: number;
    crop: Point;
    croppedImage?: Area;
}

class CropImageModal extends Component<IProps, IState> {
    state: IState = {
        zoom: 1,
        crop: { x: 0, y: 0 },
    };
    modalRef: RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e: MouseEvent) => {
        const target = e.target as Node;

        if (this.modalRef.current && !this.modalRef.current.contains(target)) {
            this.props.onCloseProfileModal();
        }
    };

    onCropChange = (crop: Point) => {
        this.setState({ crop });
    };

    onZoomChange = (zoom: number) => {
        this.setState({ zoom });
    };

    onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
        this.setState({ croppedImage: croppedAreaPixels });
    };

    createImage = (url: string) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.src = url;
        });

    getRadianAngle = (degreeValue: number) => {
        return (degreeValue * Math.PI) / 180;
    };

    rotateSize = (width: number, height: number, rotation: number) => {
        const rotRad = this.getRadianAngle(rotation);
        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    };

    getCroppedImage = async (imageSrc: string, pixelCrop: any, fileName: string, fileType: string) => {
        const image: any = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }

        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(image.width, image.height, 0);
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.translate(-image.width / 2, -image.height / 2);
        ctx.drawImage(image, 0, 0);

        const { x, y, width, height } = pixelCrop;

        const intX = Math.floor(x);
        const intY = Math.floor(y);
        const intWidth = Math.floor(width);
        const intHeight = Math.floor(height);

        try {
            if (intX < 0 || intY < 0 || intX + intWidth > bBoxWidth || intY + intHeight > bBoxHeight) {
                throw new Error('Pixel crop is out of bounds.');
            }

            const data = ctx.getImageData(intX, intY, intWidth, intHeight);

            canvas.width = intWidth;
            canvas.height = intHeight;
            ctx.putImageData(data, 0, 0);

            return new Promise<File>((resolve, reject) => {
                canvas.toBlob(
                    (file: Blob | null) => {
                        if (file) {
                            resolve(new File([file], fileName, { type: fileType }));
                        } else {
                            reject(new Error('Failed to generate file.'));
                        }
                    },
                    fileType,
                    0.1
                );
            });
        } catch (error) {
            console.error('Error processing image:', error);
            return null;
        }
    };

    handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newZoom = parseFloat(event.target.value);
        this.onZoomChange(newZoom);
    };

    onConfimClick = async () => {
        const croppedImage: any = await this.getCroppedImage(
            this.props.displayUploadUrl,
            this.state.croppedImage,
            this.props.uploadFilePath?.name ?? '',
            this.props.uploadFilePath?.type ?? ''
        );

        this.props.handleCropModal(false, croppedImage);
        this.setState({ zoom: 1 });
    };

    render() {
        const { imgCropModalOpen, displayUploadUrl } = this.props;
        const { crop, zoom } = this.state;

        return (
            <Modal className='crop-photo-update-modal' show={imgCropModalOpen} backdrop={false}>
                <div ref={this.modalRef}>
                    <Modal.Header>
                        <Modal.Title>Crop Profile Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='crop-container-wrapper'>
                            <div className='crop-container'>
                                <Cropper
                                    image={displayUploadUrl}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                    cropShape='round'
                                />
                            </div>
                            <Form className='image-zoom-wrapper'>
                                <Form.Group controlId='imageZoomSlider' className='image-zoom-container'>
                                    <Form.Label>Zoom:</Form.Label>
                                    <Form.Control
                                        type='range'
                                        min='1'
                                        max='3'
                                        step='0.1'
                                        value={zoom}
                                        onChange={this.handleZoomChange}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='button-wrapper'>
                            <Button className={`bp confirm-profile`} onClick={this.onConfimClick}>
                                Confirm
                            </Button>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}

export default CropImageModal;
