import React, { Component } from 'react';
import shortEnglishHumanizer from 'src/helpers/shortEnglishHumanizer';
import { Button } from 'react-bootstrap';
import { IQuiz } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz';
import { IUserLessonCardData } from '../../../../UserLessonCard';
import PreviousAttempts from '../../Components/PreviousAttempts';
import Quiz from 'src/pages/Course/Stages/Lessons/LessonTypes/Slide/Quiz/Quiz';
import { Api, EventBus } from 'src/helpers/new';
import LessonContext from 'src/pages/Course/Stages/Lessons/LessonContext';
import './card.quiz.scss';
import { isEmpty, isNil } from 'lodash';

interface IProps {
    quiz: IQuiz;
    userLessonCardData: IUserLessonCardData;
    cardId: string;
    course: any;
}

interface IState {
    message: any | null;
    userInQuiz: boolean;
    attemptId?: string;
    cardId: string;
}

export default class CardQuiz extends Component<IProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    state: IState = {
        message: null,
        userInQuiz: false,
        cardId: '',
    };

    componentDidMount(): void {
        EventBus.on('leave-quiz', this.leaveQuiz);
        EventBus.on('review-quiz', this.reviewQuiz);
    }

    componentWillUnmount(): void {
        EventBus.remove('leave-quiz', this.leaveQuiz);
        EventBus.remove('review-quiz', this.reviewQuiz);
    }

    leaveQuiz = () => {
        this.setState({ userInQuiz: false, attemptId: undefined, cardId: '' });
        if (this.context.loadUpdatedLesson) this.context.loadUpdatedLesson();
    };

    reviewQuiz = (e: Event) => {
        const { attemptId, cardId } = (e as CustomEvent).detail;

        this.setState({ userInQuiz: true, attemptId, cardId });
    };

    startNewQuiz = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Quiz in progress',
            body: 'You already have a quiz in progress, do you want to resume?',
            confirm: {
                text: 'Resume quiz',
                action: this.enterQuiz,
            },
            cancel: {
                text: 'Start new quiz',
                action: this.handleCancel,
            },
            dismiss: () => {},
        });
    };

    handleCancel = async () => {
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz/submit', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            quizInProgress: this.quizInProgress,
        });
        if (success) {
            if (response && !isEmpty(response.updatedCards) && this.context.updateUserLessonCards) {
                this.context.updateUserLessonCards(response.updatedCards);
            }
            if (this.context.setTooltip) this.context.setTooltip(response.tooltip);
            this.enterQuiz();
        }
    };

    enterQuiz = async () => {
        this.setState({ userInQuiz: true, attemptId: undefined, cardId: this.props.cardId });
    };

    get quizInProgress(): boolean {
        return !!this.props.userLessonCardData?.quiz?.attemptInProgress?.quiz;
    }

    get quiz(): IQuiz {
        return this.props?.userLessonCardData?.quiz?.attemptInProgress?.quiz ?? this.props.quiz;
    }

    isStartQuizBtnVisible = () => {
        const quiz = this.props.quiz;
        const previousAttempts = this.props.userLessonCardData?.quiz?.previousAttempts ?? [];
        const attemptPassed = this.props.userLessonCardData?.quiz?.quizPassed ?? false;
        if (previousAttempts.length > 0) {
            switch (quiz.allowReattempt) {
                case 'never':
                    return false;
                case 'always':
                    return true;
                case 'on_fail':
                    return !attemptPassed;
                default:
                    return false;
            }
        } else {
            return true;
        }
    };

    getNoOfQuestions = (subSetLength: number, questionLength: number) => {
        let count = subSetLength;
        if (count > questionLength || count === 0) {
            count = questionLength;
        }
        return count;
    };

    getChapterMasteryRequireInfo = () => {
        if (
            this.context.lesson?.unlockNextLesson.includes('CHAPTER_MASTERY_REQUIREMENT') &&
            (this.context.lesson?.timeSpentHistory ?? []).length > 0
        ) {
            const fullScoreCounts = (this.props.userLessonCardData?.quiz?.previousAttempts ?? []).filter(
                (quiz) => +(quiz?.percentageScore ?? 0) === 100,
            ).length;

            if (fullScoreCounts > 2) {
                return null;
            } else {
                return (
                    <p>
                        Pass attempts:&nbsp;
                        {fullScoreCounts}/2 Passed
                    </p>
                );
            }
        }

        return null;
    };

    render() {
        const { quiz } = this.props.userLessonCardData;

        if (this.state.userInQuiz) {
            return (
                <div className='page-card-quiz-popup'>
                    <div className='quiz-container'>
                        <Quiz
                            cardId={this.state.cardId}
                            course={this.props.course}
                            attemptId={this.state.attemptId}
                            withHeader={true}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className='page-card-quiz'>
                <div
                    className={`quiz__info ${
                        (isNil(this.props.quiz?.quizType) || this.props.quiz?.quizType === 'inline') &&
                        'quiz__unset-default-background'
                    }`}
                >
                    <h4>
                        <b>{this.quiz?.title ?? ''}</b>
                    </h4>
                    {this.state.message && (
                        <div className={`quiz__alert quiz__alert--${this.state.message.type}`}>
                            <h6>Passed</h6>
                            <span>{this.state.message.text}</span>
                        </div>
                    )}
                    <div className='quiz__footer'>
                        <div className='quiz__data'>
                            <p>
                                Number of questions:{' '}
                                {this.getNoOfQuestions(
                                    this.quiz?.questionSubsetCount ?? 0,
                                    this.quiz?.questions?.length ?? 0,
                                )}
                            </p>
                            <p>Passing percentage: {this.quiz?.passPercentage ?? 0}%</p>
                            <p>
                                Time limit:&nbsp;
                                {shortEnglishHumanizer((this.quiz?.timeLimit ?? 0) * 60000)}
                            </p>
                            {this.getChapterMasteryRequireInfo()}
                        </div>
                        {this.quizInProgress && (
                            <Button className='bp' onClick={this.enterQuiz}>
                                Resume quiz
                            </Button>
                        )}
                        {this.isStartQuizBtnVisible() && (
                            <Button
                                className='bp'
                                onClick={this.quizInProgress ? this.startNewQuiz : this.enterQuiz}
                                disabled={!this.quiz}
                            >
                                Start {this.quizInProgress ? 'new ' : ''} quiz
                            </Button>
                        )}
                    </div>
                </div>
                {!!quiz?.previousAttempts?.[0] && (
                    <PreviousAttempts
                        cardId={this.props.cardId}
                        quizAttempts={this.props?.userLessonCardData?.quiz.previousAttempts}
                        cardIndex={0}
                    />
                )}
            </div>
        );
    }
}
