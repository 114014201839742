import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import usStates from 'src/helpers/usStates';
import CheckoutContext from '../../../CheckoutContext';
import Next from '../../Next';
import './Billing.scss';
import { CheckoutValidateHelper } from '../CheckoutValidateHelper';
import { concat } from 'lodash';

export interface IBilling {
    streetLines: string[];
    town: string;
    state: string;
    zipCode: string;
    firstName: string;
    lastName: string;
    startedAt: string;
    updatedAt: string;
    createdAt: string;
}

interface IState {
    billing: IBilling;
    validation: {
        addressLineMessage: string;
        townMessage: string;
        zipCodeMessage: string;
        firstNameMessage: string;
        lastNameMessage: string;
    };
}

export default class Billing extends Component<unknown, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;

    constructor(props: unknown, context: any) {
        super(props);
        this.state = {
            billing: {
                ...context.billingAddress,
                startedAt: context.billingAddress.startedAt || new Date(),
            },
            validation: {
                addressLineMessage: '',
                townMessage: '',
                zipCodeMessage: '',
                firstNameMessage: '',
                lastNameMessage: '',
            },
        };
    }

    componentDidMount() {
        EventBus.on('validate-billing-tab', this.validateInputs);
    }

    componentWillUnmount() {
        EventBus.remove('validate-billing-tab', this.validateInputs);
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        this.setState((prevState) => {
            const billing = { ...prevState.billing };
            //@ts-ignore
            billing[e.target.name] = e.target.value;

            this.context.updateCheckoutState({
                billingAddress: {
                    ...billing,
                },
            });

            return { billing };
        });
    };

    handleStreetLineInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState) => {
            const billing = { ...prevState.billing };
            billing.streetLines[parseInt(e.target.name)] = e.target.value;

            return { billing };
        });
    };

    validateInputs = async () => {
        const { proceed, messages } = CheckoutValidateHelper.validateBilling(this.state.validation, this.state.billing);
        const { isAdminPreview } = this.context;

        if (proceed || isAdminPreview) {
            this.context.switchTab(3, true);
            await this.context.updateCart({
                billingAddress: {
                    ...this.state.billing,
                    createdAt: this.context.billingAddress.createdAt ?? new Date(),
                    updatedAt: new Date(),
                },
                currentTab: 3,
            });
            if (window.fbq && typeof window.fbq === 'function' && !isAdminPreview) {
                window.fbq('track', 'AddPaymentInfo');
            }
        } else {
            this.setState({
                validation: messages,
            });
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    validateNames = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[a-zA-Z_-]/.test(event.key)) {
            event.preventDefault();
        }
    };

    render() {
        const { streetLines, town, state, zipCode, firstName, lastName } = this.state.billing;
        const { firstNameMessage, lastNameMessage, townMessage, zipCodeMessage, addressLineMessage } =
            this.state.validation;
        return (
            <>
                <div className='checkout-billing'>
                    <h1>Billing address</h1>
                    <h3>Who should we send the bill?</h3>

                    <div className='checkout-form'>
                        <div>
                            <label htmlFor='aFirstName'>First Name *</label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName ?? ''}
                                id='aFirstName'
                                onChange={this.handleInputChange}
                                onKeyPress={this.validateNames}
                            />
                            <p>{firstNameMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='aLastName'>Last Name *</label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName ?? ''}
                                id='aLastName'
                                onChange={this.handleInputChange}
                                onKeyPress={this.validateNames}
                            />
                            <p>{lastNameMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='aAddressLine0'>Address line *</label>
                            <input
                                type='text'
                                id='aAddressLine0'
                                value={streetLines[0] ?? ''}
                                name='0'
                                onChange={this.handleStreetLineInputChange}
                            />
                            <p>{addressLineMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='aTown'>City *</label>
                            <input
                                type='text'
                                name='town'
                                value={town ?? ''}
                                id='aTown'
                                onChange={this.handleInputChange}
                            />
                            <p>{townMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='aState'>State *</label>
                            <select
                                name='state'
                                id='aState'
                                value={state ?? 'none'}
                                onChange={this.handleInputChange}
                                className={`stateDropddown`}
                            >
                                {concat([{ value: 'none', key: 'none' }], usStates).map(
                                    ({ key, value }: { key: string; value: string }) => {
                                        if (key === 'none') {
                                            return (
                                                <option value={value} key={key} disabled selected>
                                                    {``}
                                                </option>
                                            );
                                        }
                                        return (
                                            <option value={key} key={key}>
                                                {key}
                                            </option>
                                        );
                                    },
                                )}
                            </select>
                        </div>
                        <div>
                            <label htmlFor='aZipCode'>Zip code *</label>
                            <input
                                className='zip-code-input'
                                name='zipCode'
                                value={zipCode ?? ''}
                                id='aZipCode'
                                onChange={this.handleInputChange}
                                onKeyPress={(event) => {
                                    if (!/\d/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <p>{zipCodeMessage}</p>
                        </div>
                    </div>
                </div>
                <Next onClick={this.validateInputs} />
            </>
        );
    }
}
