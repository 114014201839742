import React, { Component, RefObject } from 'react';
import { RouteComponentProps } from 'src/hoc/withRouter';
import { ILoggedIn, IUserLoggedIn } from './ProfilePopOver';
import { Modal, Spinner, Button } from 'react-bootstrap';
import './ProfilePhotoUpdateModal.scss';
import NameAvatar from './NameAvatar';
import { isEmpty } from 'lodash';
import { Api } from 'src/helpers/new';
import CropImageModal from './CropImageModal';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IProps extends RouteComponentProps, ILoggedIn {
    onSetShowUploadPhoto: (show: boolean) => void;
    isShowUpload: boolean;
}

interface IState {
    isLoading: boolean;
    displayUploadUrl: string;
    uploadFilePath: any;
    isDirty: boolean;
    isRemovedProfile: boolean;
    croppedFilePath: File | string;
    imgCropModalOpen: boolean;
}

class ProfilePhotoUpdateModal extends Component<IProps, IState> {
    state: IState = {
        isLoading: false,
        displayUploadUrl: '',
        isDirty: false,
        uploadFilePath: '',
        isRemovedProfile: false,
        imgCropModalOpen: false,
        croppedFilePath: '',
    };
    fileUploadExtension = '.png,.jpg,.jpeg,.webp,.svg';
    modalRef: RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onClose = () => {
        this.setState({
            isRemovedProfile: false,
            displayUploadUrl: '',
            uploadFilePath: '',
            isDirty: false,
            imgCropModalOpen: false,
        });
        this.props.onSetShowUploadPhoto(false);
    };

    handleClickOutside = (e: MouseEvent) => {
        const target = e.target as Node;

        if (this.modalRef.current && !this.modalRef.current.contains(target) && !this.state.imgCropModalOpen) {
            this.onClose();
        }
    };

    onUpload = () => {
        document?.getElementById('profilePhoto')?.click();
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value && event.target.files) {
            const file = event.target.files[0],
                url = URL.createObjectURL(file);
            const extension = '.' + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
            if (this.fileUploadExtension.includes(extension)) {
                this.setState({
                    displayUploadUrl: url,
                    isDirty: true,
                    uploadFilePath: file,
                    imgCropModalOpen: true,
                });
            }
        }
    };

    onUpdatPhoto = async () => {
        this.setState({ isLoading: true });
        if (this.state.croppedFilePath) {
            const fileData = new FormData();
            fileData.append('file', this.state.croppedFilePath);
            const { success, response } = await Api.call('POST', '/files', fileData);

            if (success) {
                const mainUrl = Array.isArray(response.url) ? response.url[2] : response.url;
                const { success: profileSucsess, response: rspProfile } = await Api.call('PUT', '/users/profile', {
                    image: response.fileId,
                });

                if (profileSucsess) {
                    this.props.setLoggedIn({
                        ...this.props.loggedIn,
                        user: {
                            ...this.props.loggedIn.user,
                            image: response.fileId,
                            file: rspProfile.file,
                        },
                    });
                    this.setState({
                        displayUploadUrl: mainUrl,
                    });
                }
            }
        } else if (this.state.isRemovedProfile) {
            const { success, response } = await Api.call('PUT', '/users/profile', {
                image: '',
            });
            if (success) {
                this.props.setLoggedIn({
                    ...this.props.loggedIn,
                    user: {
                        ...this.props.loggedIn.user,
                        image: response?.image ?? '',
                        file: response.file,
                    },
                });
            }
        }

        this.setState(
            {
                displayUploadUrl: '',
                isDirty: false,
                uploadFilePath: '',
                isLoading: false,
            },
            () => {
                this.onClose();
            }
        );
    };

    handleCropModal = (isOpen: boolean, croppedImage: File) => {
        const url = URL.createObjectURL(croppedImage);
        this.setState({ displayUploadUrl: url, imgCropModalOpen: isOpen, croppedFilePath: croppedImage });
    };

    onRemoveProfile = async () => {
        this.setState({
            displayUploadUrl: '',
            isDirty: true,
            uploadFilePath: '',
            isRemovedProfile: true,
            croppedFilePath: '',
        });
    };

    render() {
        const { displayUploadUrl, imgCropModalOpen, uploadFilePath } = this.state;
        const imageUrl = !isEmpty(displayUploadUrl)
            ? Array.isArray(displayUploadUrl)
                ? displayUploadUrl[2]
                : displayUploadUrl
            : this.state.isRemovedProfile
            ? ''
            : this.props.loggedIn?.user?.file?.url[0];

        return (
            <>
                <Modal className='profile-photo-update-modal' show={this.props.isShowUpload} backdrop={false}>
                    <div ref={this.modalRef}>
                        <Modal.Header>
                            <Modal.Title>Change your profile picture</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='profile-upload-content-wrapper'>
                                <div className='upload-button-wrapper'>
                                    <p onClick={this.onUpload}>
                                        <input
                                            type='file'
                                            accept={this.fileUploadExtension}
                                            id={'profilePhoto'}
                                            name={'profilePhoto'}
                                            onChange={this.handleFileChange}
                                            onClick={(e: any) => {
                                                e.target.value = null;
                                            }}
                                            style={{
                                                display: 'none',
                                            }}
                                        />
                                        <i className='fa-solid fa-arrow-up-from-bracket' />
                                        <span>Upload picture</span>
                                    </p>
                                    <p onClick={this.onRemoveProfile}>
                                        <i className='fa-solid fa-trash' />
                                        <span>Remove picture</span>
                                    </p>
                                </div>
                                <div>
                                    {!isEmpty(imageUrl) ? (
                                        <img className='profile-photo' src={imageUrl} alt='profile photo' />
                                    ) : (
                                        <NameAvatar
                                            className='profile-avatar'
                                            firstName={this.props.loggedIn?.user?.firstName ?? ''}
                                            lastName={this.props.loggedIn?.user?.lastName ?? ''}
                                        />
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='button-wrapper'>
                                <div className='close-button-wrapper'>
                                    <Button
                                        className={`btn ${this.state.isLoading ? 'button--disabled' : ''}`}
                                        onClick={this.onClose}
                                        disabled={this.state.isLoading}
                                    >
                                        Close
                                    </Button>
                                </div>
                                <Button
                                    className={`bp save-profile ${!this.state.isDirty ? 'button--disabled' : ''}`}
                                    onClick={this.onUpdatPhoto}
                                >
                                    {this.state.isLoading ? (
                                        <>
                                            <Spinner animation='border' size='sm' /> Updating
                                        </>
                                    ) : (
                                        `Save`
                                    )}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>
                <CropImageModal
                    displayUploadUrl={displayUploadUrl}
                    imgCropModalOpen={imgCropModalOpen}
                    handleCropModal={this.handleCropModal}
                    uploadFilePath={uploadFilePath}
                    onCloseProfileModal={this.onClose}
                />
            </>
        );
    }
}

export default withRouterAndRedux(
    ProfilePhotoUpdateModal,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: IUserLoggedIn) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    }
);
