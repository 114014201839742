import React, { Component } from 'react';
import { Spinner } from 'src/components/Spinner';
import Progress from './Progress/Progress';
import TabContent from './TabContent';
import OrderSummary from './OrderSummary';
import './StepCheckout.scss';
import StudentPreviewControls from '../../Course/Stages/Lessons/StudentPreviewController/StudentPreviewControls';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
interface IProps extends RouteComponentProps {
    currentTab: number;
    isAdminPreview: boolean;
    state: string;
    onSetTermsAndConditions: (value: string) => void;
    pkgTermsConditionsCheckboxToggle: (value: string) => void;
    status: string;
}
interface IState {}
class Checkout extends Component<IProps, IState> {
    state = {};

    render() {
        const { currentTab, isAdminPreview, state, status } = this.props;
        return (
            <div className={`checkout-container ${currentTab === 3 ? 'checkout-payment-container' : ''}`}>
                {isAdminPreview && (
                    <div className='checkout-page-preview-settings-container'>
                        <StudentPreviewControls isCheckout={true} />
                    </div>
                )}
                <h1 className='heading'>
                    The fastest and most affordable way to become a licensed real estate agent
                    {state ? ` in ${state}` : ''}!
                </h1>
                <div className={`checkout-main ${currentTab === 3 ? 'checkout-payment-stage' : ''}`}>
                    {status === 'LOADING' ? (
                        <Spinner />
                    ) : (
                        <>
                            {status === 'EMPTY' && (
                                <>
                                    <h4>Your cart is empty</h4>
                                    <a href='https://realestateu.com/' target='_blank' rel='noreferrer'>
                                        Browse our packages
                                    </a>
                                </>
                            )}
                            {status === 'READY' && (
                                <>
                                    <OrderSummary />
                                    <Progress />
                                    <TabContent
                                        onSetTermsAndConditions={this.props.onSetTermsAndConditions}
                                        pkgTermsConditionsCheckboxToggle={this.props.pkgTermsConditionsCheckboxToggle}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Checkout);
