import React, { useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { CheckoutSurveyPrompt } from '../Survey/CheckoutSurveyPrompt';

interface UsePromptProps {
    when?: boolean;
    cartDetails: {
        cartId: string;
        id: string;
    };
    onSubmit: () => void;
    onClose: () => void;
}

export function SurveyBlockerForm({ when = true, cartDetails, onSubmit, onClose }: UsePromptProps) {
    const [isPromptVisible, setIsPromptVisible] = useState(false);

    const blocker: any = useBlocker(({ currentLocation, nextLocation }) => {
        if (when) {
            setIsPromptVisible(true);
            return true;
        }
        return false;
    });

    const onSurveySubmit = () => {
        setIsPromptVisible(false);
        blocker.proceed();
        if (onSubmit) {
            onSubmit();
        }
    };

    const onSurveyClose = () => {
        setIsPromptVisible(false);
        blocker.reset();
        if (onClose) {
            onClose();
        }
    };

    return isPromptVisible ? (
        <CheckoutSurveyPrompt
            isShow={isPromptVisible}
            onSubmit={onSurveySubmit}
            onClose={onSurveyClose}
            cartDetails={cartDetails}
        />
    ) : null;
}
